import axios from 'axios';
import { userNotAuthorised } from '../Redux/Actions/AuthActions';
import { store } from '../Redux/store';
import { SHOW_ERROR_MESSAGE, HIDE_ERROR_MESSAGE } from '../Const/ActionTypes';
import { api_endpoint } from '../app.config';
export const crudService = {
  get,
  post,
  deleteEndpoint,
  put,
};
function get(apiEndpoint) {
  return axios
    .get(api_endpoint + apiEndpoint, {
      headers: {
        Authorization: `Bearer ${localStorage.mUfjka563lPs}`,
      },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      if (err?.response?.data?.error?.statusCode === 401) {
        store.dispatch(userNotAuthorised());
      } else {
        let errorMsg =
          err.response === undefined
            ? 'Please try again later, an error has occurred'
            : err.response.data.error.message;
        store.dispatch({
          type: SHOW_ERROR_MESSAGE,
          payload: errorMsg,
        });
        setTimeout(() => {
          store.dispatch({ type: HIDE_ERROR_MESSAGE });
        }, 4000);
      }
    });
}

function post(apiEndpoint, payload) {
  return axios
    .post(api_endpoint + apiEndpoint, payload, {
      headers: {
        Authorization: `Bearer ${localStorage.mUfjka563lPs}`,
      },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      if (err?.response?.data?.error?.statusCode === 401) {
        store.dispatch(userNotAuthorised());
      } else {
        let errorMsg =
          err.response === undefined
            ? 'Please try again later, an error has occurred '
            : err.response.data.error.message;
        store.dispatch({
          type: SHOW_ERROR_MESSAGE,
          payload: errorMsg,
        });
        setTimeout(() => {
          store.dispatch({ type: HIDE_ERROR_MESSAGE });
        }, 4000);
      }
    });
}

function deleteEndpoint(apiEndpoint) {
  return axios
    .delete(api_endpoint + apiEndpoint, {
      headers: {
        Authorization: `Bearer ${localStorage.mUfjka563lPs}`,
      },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      if (err?.response?.data?.error?.statusCode === 401) {
        store.dispatch(userNotAuthorised());
      } else {
        let errorMsg =
          err.response === undefined
            ? 'Please try again later, an error has occurred '
            : err.response.data.error.message;
        store.dispatch({
          type: SHOW_ERROR_MESSAGE,
          payload: errorMsg,
        });
        setTimeout(() => {
          store.dispatch({ type: HIDE_ERROR_MESSAGE });
        }, 4000);
      }
    });
}

function put(apiEndpoint, payload) {
  return axios
    .put(api_endpoint + apiEndpoint, payload, {
      headers: {
        Authorization: `Bearer ${localStorage.mUfjka563lPs}`,
      },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      if (err?.response?.data?.error?.statusCode === 401) {
        store.dispatch(userNotAuthorised());
      } else {
        let errorMsg =
          err.response === undefined
            ? 'Please try again later, an error has occurred '
            : err.response.data.error.message;
        store.dispatch({
          type: SHOW_ERROR_MESSAGE,
          payload: errorMsg,
        });
        setTimeout(() => {
          store.dispatch({ type: HIDE_ERROR_MESSAGE });
        }, 4000);
      }
    });
}
