export const site_path = process.env.REACT_APP_SITE_PATH
export const api_endpoint = process.env.REACT_APP_API_ENDPOINT
export const spec_endpoint = process.env.REACT_APP_SPEC_ENDPOINT
export const login_path = process.env.REACT_APP_LOGIN_PATH

export default {
    site_path,
    api_endpoint,
    spec_endpoint,
    login_path,
}