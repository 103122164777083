import React from 'react';
import { site_path } from '../../app.config';

const PrivateRoute = ({ component: Component, authToken, ...rest }) => {
  if (!authToken) {
    window.location.replace(site_path);
  }

  return <Component {...rest} />;
};

export default PrivateRoute;
